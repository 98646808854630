/* eslint-disable camelcase */ import { MatieresLycee } from "./matieres";
import { getFiliereForBacPro } from "./bacs-pro";
import { includes, pick, extend, cloneDeep } from "lodash";
export var HorsScopeClasses = {
    Collège: "Coll\xe8ge",
    Seconde: "Seconde"
};
var Classes = {
    Collège: "Coll\xe8ge",
    Seconde: "Seconde",
    Première: "Premi\xe8re",
    Terminale: "Terminale",
    "Post-bac": "Post-bac"
};
var TypesBac = {
    bac_general: "G\xe9n\xe9ral (r\xe9forme 2021)",
    S: "G\xe9n\xe9ral S",
    L: "G\xe9n\xe9ral L",
    ES: "G\xe9n\xe9ral ES",
    bac_techno: "Technologique",
    bac_pro: "Professionnelle",
    Autre: "Autre dipl\xf4me"
};
var Bacs2019 = {
    bac_general: "Voie G\xe9n\xe9rale",
    bac_techno: "Voie Technologique",
    bac_pro: "Voie Professionnelle"
};
var Bacs = {
    ES: "ES",
    SVT: "S",
    SI: "S - SI",
    EAT: "S - EAT",
    L: "L",
    STD2A: "STD2A",
    STI2D: "STI2D",
    STMG: "STMG",
    STAV: "STAV",
    STHR: "STHR",
    STL: "STL",
    ST2S: "ST2S",
    TMD: "TMD",
    bac_pro: "Bac Pro",
    Autre: "Autre dipl\xf4me"
};
// Pistes can have additional Bac options
var ExtendedBacs = extend(cloneDeep(Bacs), {
    STHR: "STHR",
    TMD: "TMD",
    BPagricole: "BP agricole",
    BPindustriel: "BP industriel",
    BPcommercial: "BP tertiaire-Commercial",
    BPservices: "BP tertiaire - services",
    BPart: "BP art-artisanat",
    EsMath: "ES sp\xe9 Maths",
    EsTout: "ES toutes sp\xe9cialit\xe9s"
});
var FinanceStudies = [
    {
        value: "crous",
        label: "Bourse du Crous"
    },
    {
        value: "boursePrivee",
        label: "Bourse Priv\xe9e"
    },
    {
        value: "pretEtudiant",
        label: "Pr\xeat \xe9tudiant"
    },
    {
        value: "jobEtudiant",
        label: "Boulot \xe9tudiant"
    }, 
];
/** Mapping Bac -> MatieresLycee * */ var MatieresLyceeForSeconde = {
    BP_agricole: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement", 
    ],
    BP_industriel: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_gestion", 
    ],
    BP_tertiaire_commercial: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_droit", 
    ],
    BP_tertiaire_services: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_droit",
        "unites_facultatives", 
    ],
    BP_artisanat: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement", 
    ]
};
var MatieresLyceeForPremiere = {
    G: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "enseignements_scientifiques",
        "eps", 
    ],
    /** Bacs techno * */ STMG: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STMG_gestion",
        "STMG_management",
        "STMG_droit-economie", 
    ],
    ST2S: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "mathematiques",
        "eps",
        "ST2S_pc-sante",
        "ST2S_bio-physio",
        "ST2S_sanitaire-sociale", 
    ],
    STHR: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STHR_alimentation-env",
        "STHR_tech-culinaire",
        "STHR_gestion-hoteliere", 
    ],
    STI2D: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STI2D_innovation-techno",
        "STI2D_ing-dev-durable",
        "Physique-chimie-maths", 
    ],
    STL: [
        "lv1",
        "lv2",
        "histoire_geo",
        "francais",
        "mathematiques",
        "eps",
        "Physique-chimie-maths",
        "STL_biochimie-biologie",
        "STL_biotech", 
    ],
    STD2A: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STD2A_physique-chimie",
        "STD2A_numerique",
        "STD2A_design-arts", 
    ],
    TMD: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "mathematiques",
        "eps",
        "TMD_instrument_danse", 
    ],
    STAV: [
        "lv1",
        "lv2",
        "francais",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STAV_gestion_alimentation",
        "STAV_territoire_societe",
        "STAV_technologie", 
    ],
    /** Bacs pros ** */ BP_agricole: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement", 
    ],
    BP_industriel: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_gestion", 
    ],
    BP_tertiaire_commercial: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_droit", 
    ],
    BP_tertiaire_services: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_droit",
        "unites_facultatives", 
    ],
    BP_artisanat: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement", 
    ],
    bac_general: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "enseignements_scientifiques", 
    ],
    bac_techno: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques", 
    ]
};
/** Mapping Bacs -> MatieresLycee * */ var MatieresLyceeForTerminale = {
    G: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "enseignements_scientifiques",
        "eps", 
    ],
    /** Bacs technos * */ STMG: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STMG_management-gestion",
        "STMG_droit-economie", 
    ],
    ST2S: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "mathematiques",
        "eps",
        "ST2S_chimie-bio-physio",
        "ST2S_sanitaire-sociale", 
    ],
    STHR: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STHR_alimentation-env-tech-culinaire",
        "STHR_gestion-hoteliere", 
    ],
    STI2D: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STI2D_innovation-techno-durable",
        "Physique-chimie-maths", 
    ],
    STL: [
        "lv1",
        "lv2",
        "histoire_geo",
        "francais",
        "philosophie",
        "mathematiques",
        "eps",
        "Physique-chimie-maths",
        "STL_biochimie-biotech", 
    ],
    STD2A: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STD2A_method-design",
        "STD2A_design-arts", 
    ],
    TMD: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "mathematiques",
        "eps",
        "TMD_instrument_danse", 
    ],
    STAV: [
        "lv1",
        "lv2",
        "francais",
        "philosophie",
        "histoire_geo",
        "mathematiques",
        "eps",
        "STAV_gestion_alimentation",
        "STAV_territoire_techno", 
    ],
    // bacs pros
    BP_agricole: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement", 
    ],
    BP_industriel: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_gestion", 
    ],
    BP_tertiaire_commercial: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_droit", 
    ],
    BP_tertiaire_services: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement",
        "eco_droit", 
    ],
    BP_artisanat: [
        "lv1",
        "lv2",
        "eps",
        "francais",
        "histoire_geo",
        "mathematiques",
        "arts_appliques_cultures_artistiques",
        "prevention_sante_environnement", 
    ],
    bac_general: [
        "lv1",
        "lv2",
        "eps",
        "philosophie",
        "histoire_geo",
        "enseignements_scientifiques", 
    ],
    bac_techno: [
        "lv1",
        "lv2",
        "eps",
        "philosophie",
        "histoire_geo",
        "mathematiques", 
    ]
};
function MatieresLyceeForBac(param) {
    var filiere = param.filiere, classe = param.classe, filiereBacPro = param.filiereBacPro, filiereTechno = param.filiereTechno;
    var serie;
    if (filiere === "bac_pro") {
        // if there's no filiereBacPro (ie: Seconde), default to BP_tertiaire
        // to get Matieres from Tronc Commum
        serie = getFiliereForBacPro(filiereBacPro) || "BP_tertiaire_services";
    }
    if (filiere === "bac_techno") {
        serie = filiereTechno;
    }
    if (filiere === "bac_general") {
        serie = "G";
    }
    if (classe === "seconde") {
        return MatieresLyceeForSeconde[serie];
    }
    if (classe === "Premi\xe8re" || classe === "premiere") {
        return MatieresLyceeForPremiere[serie];
    }
    return MatieresLyceeForTerminale[serie];
}
var TerminaleOptions = pick(MatieresLycee, "droits_et_enjeux_du_monde_contemporain", "mathematiques_expertes", "mathematiques_complementaires");
function TerminaleOptionsForSpes(spesTerminale, speAbandonne) {
    if (includes(spesTerminale, "mathematiques")) {
        return pick(MatieresLycee, "droits_et_enjeux_du_monde_contemporain", "mathematiques_expertes");
    }
    if (includes(speAbandonne, "mathematiques")) {
        return pick(MatieresLycee, "droits_et_enjeux_du_monde_contemporain", "mathematiques_complementaires");
    }
    return pick(MatieresLycee, "droits_et_enjeux_du_monde_contemporain");
}
var BoostOptions = [
    {
        label: "Oui",
        value: 4
    },
    {
        label: "Un peu",
        value: 3
    },
    {
        label: "Pas vraiment",
        value: 2
    },
    {
        label: "Non",
        value: 1
    }, 
];
var Sexe = {
    fille: "Femme",
    garcon: "Homme",
    autre: "Autre",
    ne_souhaite_pas_repondre: "Je ne souhaite pas r\xe9pondre"
};
var EncadrementOptions = {
    fort: "Etre accompagn\xe9.e et avoir des devoirs sur table tr\xe8s r\xe9guli\xe8rement (toutes les semaines)",
    moyen: "Tu ne sais pas / \xe7a t'est \xe9gal",
    faible: "Organiser ton temps et ton travail avec des contr\xf4les peu fr\xe9quents"
};
var SeLancerDansEtudesSupOptions = {
    tresFort: "\xc7a ne t'inqui\xe8te pas, tu as h\xe2te d'y\xa0\xeatre !",
    fort: "Tu ne te rends pas trop compte pour l'instant, mais \xe7a ne t'inqui\xe8te pas plus que \xe7",
    moyen: "Il te manque des infos et \xe7a t'inqui\xe8te un peu.",
    faible: " C'est tr\xe8s flou et \xe7a t'inqui\xe8te beaucoup."
};
var EnvironnementOptions = {
    lycee: "Au Lyc\xe9e (BTS, Classe pr\xe9pa...)",
    fac: "\xc0 l'universit\xe9 (Licence, BUT...)",
    ecole: "Dans une \xe9cole (\xe9cole post-bac en informatique, \xe9cole d’architecture...)",
    cfa: "En CFA (Centre de Formation des Apprentis)"
};
var BoursierOptions = {
    oui: "Oui",
    non: "Non",
    "ne-sais-pas": "Je ne sais pas"
};
var SignupSources = {
    article1: "Je fais partie d’un autre programme d’Article 1",
    engagement: "Je fais partie d’un programme de valorisation de l’engagement",
    amis: "Par le bouche \xe0 oreille, des relations",
    presse_media: "Par les m\xe9dias",
    reseaux_sociaux: "Par les r\xe9seaux sociaux",
    etablissement_scolaire: "Par mon universit\xe9",
    asso_etudiante: "Par une f\xe9d\xe9ration/association \xe9tudiante",
    autre: "Autre (\xe0 pr\xe9ciser)"
};
var SkippingYearOccupations = {
    anneeSabatique: "Ann\xe9e sabbatique",
    emploi: "Emploi",
    serviceCivique: "Service civique",
    autre: "Autre"
};
var SpesPremiere2019 = {
    arts: "Arts",
    bio: "Biologie et \xe9cologie",
    eppcs: "\xc9ducation phyiques, pratiques et culture sportives",
    histoire_geo_scpo: "Histoire-g\xe9ographie, g\xe9opolitique et sciences politiques",
    litterature_philo: "Humanit\xe9s, litt\xe9rature et philosophie",
    langues_litterature_culture_etrangere: "Langues, litt\xe9ratures et cultures \xe9trang\xe8res",
    litterature_langue_culture_antiquite: "Litt\xe9rature, langues et cultures de l'Antiquit\xe9",
    mathematiques: "Math\xe9matiques",
    numerique_info: "Num\xe9rique et sciences informatiques",
    si: "Sciences de l'ing\xe9nieur",
    physique_chimie: "Physique-Chimie",
    ses: "Sciences \xe9conomiques et sociales",
    svt: "Sciences de la vie et de la terre"
};
var FilieresTechnos = {
    STMG: "STMG",
    ST2S: "ST2S",
    STHR: "STHR",
    STI2D: "STI2D",
    STL: "STL",
    STD2A: "STD2A",
    TMD: "TMD",
    STAV: "STAV"
};
var OptionsEclaireur = {
    las: "L.AS - Licence Option Acc\xe8s Sant\xe9",
    cmi: "CMI - Cursus master en ing\xe9nierie",
    autre: "Autre"
};
var OptionsEclaireurCMI = {
    acoustiqueEtVibrations: "Acoustique et Vibrations",
    chimie: "Chimie",
    economieEtGestion: "\xc9conomie et gestion",
    electroniqueEnergieElectriqueAutomatique: "\xc9lectronique, \xe9nergie \xe9lectrique, automatique",
    genieCivil: "G\xe9nie civil",
    geographieEtAmenagement: "G\xe9ographie et am\xe9nagement",
    informatique: "Informatique",
    mathematiques: "Math\xe9matiques",
    mecanique: "M\xe9canique",
    physique: "Physique",
    physiqueChimie: "Physique, chimie",
    sciencesDeLaTerre: "Sciences de la terre",
    sciencesDeLaVie: "Sciences de la vie",
    sciencesDeLaVieEtDeLaTerre: "Sciences de la vie et de la terre",
    sciencesEtTechniquesDesActivitesPhysiquesEtSportives: "Sciences et Techniques des Activit\xe9s Physiques et Sportives",
    sciencesPourLIngenieur: "Sciences pour l'ing\xe9nieur"
};
var AttitudesOptions = {
    perturbateur: "Perturbateur.rice",
    participatif: "Participatif.ve",
    determine: "D\xe9termin\xe9.e",
    debrouillard: "D\xe9brouillard.e",
    ambitieux: "Ambitieux.se",
    studieux: "Studieux.se",
    discipline: "Disciplin\xe9.e",
    discret: "Discret.\xe8te",
    pueril: "Pu\xe9ril.e",
    desinvolte: "D\xe9sinvolte",
    bavard: "Bavard.e",
    retardataire: "Retardataire"
};
export var EntretienFeedbackQuestions = {
    bonjourPro: "Le bonjour professionnel (poign\xe9e de main, formules de politesse)",
    presentation: "Pr\xe9sentation (qui je suis, ma fili\xe8re, mon projet)",
    comVerbale: "Communication verbale (niveau de langage correct et adapt\xe9)",
    comVocale: "Communication vocale (intonation, d\xe9bit de parole)",
    comCorporelle: "Communication corporelle (posture, gestuelle, expression du visage, regard)",
    clarte: "Clart\xe9 du discours (informations claires, r\xe9pondre \xe0 toutes les questions)",
    softSkills: "Comp\xe9tences transversales / soft skills (parler de comp\xe9tences transversales, donner une exp\xe9rience v\xe9cue en exemple)",
    motivation: "Motivation (avoir l’air int\xe9ress\xe9 par l’entreprise, expliquer pourquoi les missions plaisent)"
};
export { Bacs, Bacs2019, TypesBac, FilieresTechnos, Classes, Sexe, ExtendedBacs, SpesPremiere2019, MatieresLycee, EncadrementOptions, EnvironnementOptions, MatieresLyceeForBac, BoursierOptions, SignupSources, SkippingYearOccupations, BoostOptions, FinanceStudies, TerminaleOptions, TerminaleOptionsForSpes, MatieresLyceeForTerminale, OptionsEclaireur, OptionsEclaireurCMI, AttitudesOptions, SeLancerDansEtudesSupOptions };
