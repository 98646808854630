import * as Sentry from '@sentry/nextjs';

import { ERROR_CODE_SIMPLE_SCHEMA_VALIDATION } from '@inspire/data/static/error-codes';

import { didntWorkToast, invalidInputToast } from 'lib/toasts';

const logError = (error) => {
  process.env.NODE_ENV === 'development' && console.error('Error', error);
  Sentry.captureException(
    new Error(`${error.code}${error.message ? `: ${error.message}` : ''}`)
  );
};

export interface SimpleAPIError {
  code: string;
  message?: string;
  displayMessage?: string;
}

type ErrorHandlingPair = [
  errorCode: string,
  errorHandler: (error: SimpleAPIError) => any
];

export const handleErrors = (
  errors,
  ...errorHandlingPairs: ErrorHandlingPair[]
) => {
  if (!errors) return;

  let errorsHandled = 0;
  errors.forEach((error) => {
    errorHandlingPairs.forEach((handlingPair) => {
      if (handlingPair[0] === error.code || handlingPair[0] === error.message) {
        logError(error);
        handlingPair[1](error);
        errorsHandled++;
      }
    });
    if (error.code === ERROR_CODE_SIMPLE_SCHEMA_VALIDATION) {
      logError(error);
      invalidInputToast();
      errorsHandled++;
    }
  });

  if (errorsHandled !== 0) {
    return;
  }

  // If no custom error handling was found, try to show error message
  const errorMessage = errors
    .map((error) => error?.message)
    .join(' ')
    .trim();

  if (!!errorMessage) {
    errorsHandled++;
    didntWorkToast(errorMessage);
    return;
  }

  // If no custom error handling or message was found, show a generic error
  errors.forEach((error) => logError(error));
  didntWorkToast();
};
