const Snippets = {
  tags: function ({
    id,
    gtmUrl,
    gtmScript,
    events,
    dataLayer,
    dataLayerName,
    preview,
    auth,
    cookiesWin,
  }) {
    const gtmAuth = auth ? `&gtmAuth=${auth}` : '';
    const gtmPreview = preview ? `&gtmPreview=${preview}` : '';
    const gtmCookiesWin = cookiesWin ? `&gtm_cookies_win=x` : '';

    if (!id) console.warn('GTM Id is required');

    const iframe = `
      <iframe src="${gtmUrl}/ns.html?id=${id}${gtmAuth}${gtmPreview}${gtmCookiesWin}"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
          events
        ).slice(1, -1)}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='${gtmUrl}/${gtmScript}?aw='+i.replace(/^GTM-/, '')+dl+'${gtmAuth}${gtmPreview}${gtmCookiesWin}';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`;

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar,
    };
  },
  dataLayer: function (dataLayer, dataLayerName) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  },
};

export default Snippets;
